/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useContext } from "react"
import { getFromLocalStorage } from "../helpers/localStorage"
import BotCreationPopUp from "./BotCreationPopUp"
import BotMenu from "./BotMenu"
import { Table } from "antd";
import { AppContext } from "../App"
import magGlass from "../media/glass.svg"
import layoutOp from "../media/layoutIcon.svg"
import styled from "styled-components"
import BotMenuDots from "./BotMenuDots"
import progressIcon from  "../media/Oval Copy 2 (Stroke).png"

const divStyleMain = {
  display: "flex",
  justifyContent: "space-between",
  justifyItem: "center",
  margin: "2.5rem 3.5rem",
  alignItems: "center"
}


const divStyle = {
  display: "flex",
  justifyContent: "space-between",
  justifyItem: "center",
  alignItems: "center"
}

const h1Style = {
  fontStyle: "normal",
  fontFamily: "DM Sans",
  fontWeight: "700",
  fontSize: "28px",
  lineHeight: "120%",
  letterSpacing: "-0.02em",
  color: "#3A3F45",
}

const AiBotHome = () => {
  const { bots, setBots, toggle } = useContext(AppContext);

  useEffect(() => {
    setBots(getFromLocalStorage("bots"))
  }, [toggle]);

  const ImgStyle = {
    width: "150px", height: "70px", objectFit: "contain"
  }

  const StyledTable = styled(Table)`
    && {
      border: none;
      background-color: #fff;
      
      .ant-table-thead > tr > th {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        border-bottom: none;
        border-inline-end:none;
        background-color: #fff;
        color: #69727D;
      }

      .ant-table-tbody > tr > td {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        border-top: none;
        border-bottom: 1px solid #e8e8e8;
        color: #525861;
      }
  
      .ant-table-tbody > tr:first-child > td {
        border-top: 1px solid #e8e8e8;
      }
      
      ant-table-tbody > tr > td: {
        border-inline-end: none;
      }
    }
  `;


  const columns =
        [{
          title: "",
          dataIndex: "image",
          key: "image",
          render: text => <img style={ImgStyle} src={text} />,
        }, {
          title: "Website address",
          dataIndex: "address",
          key: "address",
        }, {
          title: "Created on",
          dataIndex: "created",
          key: "created",
        },
        {
          title: "Pages indexed",
          dataIndex: "pages",
          key: "pages",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (text, record) => <badge className={record.status}><img src={progressIcon} style={{paddingTop: "2px", marginRight: "2px", marginBottom: "-3px"}}/><span style={{paddingLeft:"1em"}}>{record.status}</span></badge>
        },

        {
          title: "Quick actions",
          dataIndex: "actions",
          key: "actions",
          className: "actions",
          render: (text, record) => <div><BotMenu id={record.name}/>
            <BotMenuDots/>

          </div>
        },
        ]

  return (
    <div >

      <div style={divStyleMain}>
        <h1 style={h1Style}>Chatbots</h1>
        <div style={divStyle}>
          <img src={magGlass} style={{ height: "1.5em", margin: "0 1em" }} />
          <img src={layoutOp} style={{ height: "1.5em", margin: "0 1em" }} />
          <BotCreationPopUp />
        </div>
      </div>
      <div style={{paddingRight:"3.5rem", paddingLeft:"3.5rem" }}>
        <StyledTable pagination={false} columns={columns} dataSource={bots} />
      </div>
    </div>
  )
}

export default AiBotHome