import { Layout } from "antd";
import logo from "../media/logo.svg";
import NavMenu from "./NavMenu";
const { Header } = Layout;

const headerStyle = {
  backgroundColor: "#fff",
  padding: "14px 40px",
  gap: "271px",
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  borderBottom: "1px solid #d5d8dc",
  height: "60px",
  minHeight: "60px",
  flexWrap: "nowrap",
  justifyContent: "space-between",
  position: "sticky",
  top: 0,
  zIndex: 1,
  width: "100%",
}
  
const Nav =()=> {
  return (
    <Header style={headerStyle}>
      <img src={logo} style={{height:"2em"}}/>
      <NavMenu/>
    </Header>
  )
}

export default Nav