import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";

const items = [
  {
    label: (<div style={{ display: "flex" }}>
      <div >
        <div>Elena Chugreeva</div>
        <div> elenac@elementor.com</div>
      </div>
    </div>
    ),
    key: "0",
    disabled: true
  },
  {
    type: "divider",
  },
  {
    label: <a href="#">My Profile</a>,
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: <div style={{ display: "flex" }}>
      <div >
        Log Out
      </div>
    </div>,
    key: "3",
  },
];


const DropdownStyle = {
  fontFamily: "DM Sans",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#69727D",
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  marginLeft: "auto",
}

const NavMenu = () => {
  return (
    <Dropdown menu={{ items }} trigger={["click"]} style={DropdownStyle}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <div style={{ display: "flex" }}>
            <div style={{color: "#69727D"}}>Hi, Elena!</div>
          </div>
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  )
}

export default NavMenu

